import { NextPage } from 'next';
import Link from 'next/link';
import React from 'react';

import AppHeader from '../components/app/app-header/AppHeader.component';
import AppLayout from '../components/app/app-layout/AppLayout.component';
import author from '../public/logo/author.svg';
import book from '../public/logo/book.svg';
import subject from '../public/logo/subject.svg';

const Home: NextPage = () => {
  return (
    <>
      <AppHeader topic="Ana Sayfa" />
      <AppLayout activeItem="home">
        <div className="ui grid home-page-wrapper">
          <Link href={{ pathname: '/author/AuthorCreate' }}>
            <div className="four wide column welcome">
              <img src={author} className="home-icon" />
              <div className="home-page-text">Yazar Ekle</div>
            </div>
          </Link>
          <Link href={{ pathname: 'bookSubject/createBookSubject' }}>
            <div className="four wide column welcome">
              <img src={subject} className="home-icon" />
              <div className="home-page-text">Konu Ekle</div>
            </div>
          </Link>
          <Link href={{ pathname: '/book/createBook' }}>
            <div className="four wide column welcome">
              <img src={book} className="home-icon" />
              <div className="home-page-text">Kitap Ekle</div>
            </div>
          </Link>
        </div>
        <br />
        <br />
        <div className="ui grid home-page-wrapper">
          <Link href={{ pathname: '/speaker/speakerCreate' }}>
            <div className="four wide column welcome">
              <img src={book} className="home-icon" />
              <div className="home-page-text">Konuşmacı Ekle</div>
            </div>
          </Link>
          <Link href={{ pathname: '/podcast/podcastCreate' }}>
            <div className="four wide column welcome">
              <img src={author} className="home-icon" />
              <div className="home-page-text">Podcast Ekle</div>
            </div>
          </Link>
          <Link href={{ pathname: '/program/programCreate' }}>
            <div className="four wide column welcome">
              <img src={subject} className="home-icon" />
              <div className="home-page-text">Program Ekle</div>
            </div>
          </Link>
        </div>
      </AppLayout>
    </>
  );
};

export default Home;
